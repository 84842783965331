import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { ItemCardComponent } from 'src/app/components/item-card/item-card.component';
import { TypesBarComponent } from 'src/app/components/types-bar/types-bar.component';
import { ContentSearchParams } from 'src/app/services/content/content.model';
import { ContentService } from 'src/app/services/content/content.service';
import { Subscribed } from 'src/utility-types/subscribed';
import { META_DEFAULTS, baseTitle } from './subpage.meta-const';

export const resolveSubpage = {
  pageRes: (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const contentService = inject(ContentService);
    const meta = inject(Meta);
    const title = inject(Title);

    let params: ContentSearchParams = {};

    let metaKey = '';

    if (route.params['category']) {
      params.category = route.params['category'];
      metaKey += route.params['category'];
    }

    if (route.params['type']) {
      params.type = route.params['type'];

      metaKey = metaKey.length
        ? `${metaKey}-${route.params['type']}`
        : route.params['type'];
    }

    if (route.params['tag']) {
      const tag = route.params['tag'];
      params.tag = tag;

      title.setTitle(`${baseTitle} ${tag}`);
      meta.addTags([
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: tag,
        },
      ]);

      return contentService.searchContent(params).pipe(
        catchError(err => {
          console.error('home err: ', err);
          router.navigate(['/404']);

          return of(null);
        })
      );
    } else {
      const metaObj = metaKey
        ? (META_DEFAULTS as any)[metaKey]
        : META_DEFAULTS['home'];

      title.setTitle(metaObj?.title ?? '');
      meta.addTags([
        {
          name: 'keywords',
          content: metaObj.keywords?.join(', '),
        },
        {
          name: 'description',
          content: metaObj.description,
        },
      ]);

      return contentService.searchContent(params).pipe(
        catchError(err => {
          console.error('home err: ', err);
          router.navigate(['/404']);

          return of(null);
        })
      );
    }
  },
};

type SubPageContent = Subscribed<ReturnType<ContentService['searchContent']>>;

@Component({
  selector: 'app-subpage',
  standalone: true,
  imports: [ItemCardComponent, TypesBarComponent],
  templateUrl: './subpage.component.html',
  styleUrls: ['./subpage.component.scss'],
  providers: [Title, Meta],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class SubpageComponent {
  @Input() pageRes!: SubPageContent;
  @Input() category?: string;
}
