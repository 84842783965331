<app-types-bar [category]="category" />

<div class="category">
  <h1 class="mat-headline-2 transition-page-title">
    {{
      pageRes.searchParams?.category && pageRes.searchParams?.type
        ? pageRes.searchParams?.category + ' | ' + pageRes.searchParams?.type
        : pageRes.searchParams?.category
        ? pageRes.searchParams?.category
        : pageRes.searchParams?.type
    }}
  </h1>

  @if (pageRes.content.length) {
  <div class="cards">
    @for (item of pageRes.content; track item._id) {
    <app-item-card [goSinglePost]="true" [content]="item" />
    }
  </div>
  } @else {
  <div class="empty">
    <h2 class="mat-headline-4">
      Nothing here yet, we're working hard on adding content
    </h2>
  </div>
  }
</div>
